.detail-index-top-right {
  .live-detail-top-title {
    width: (496rem / 100);
    // height: (68rem / 100);
    font-size: (22rem / 100);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273143;
    line-height: (34rem / 100);
    margin-bottom: (15rem / 100);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .live-detail-top-status {
    //width: (100rem / 100);
    height: (26rem / 100);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: (14rem / 100);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    text-align: center;
    line-height: (26rem / 100);
    margin-bottom: (12rem / 100);
  }

  .live-status-bg1 {
    background-image: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_starting.png");
    color: #ffffff;
  }

  .live-status-bg2 {
    background-image: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_not_start.png");
    color: #761f1e;
  }

  .live-detail-top-des {
    margin-bottom: (80rem / 100);

    &-ul {
      font-size: (16rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      color: #273143;
      line-height: (22rem / 100);

      &-li {
        display: flex;
        align-items: center;
        margin-bottom: (12rem / 100);

        &-icon {
          width: (24rem / 100);
          height: (24rem / 100);
          margin-right: (12rem / 100);
        }

        &-label {
          font-weight: 500;
        }
        &-tableTitle{
          position: relative;
          margin-left:50px;
    
          width: 1px;
          height: 30px;
          background-color: #d4d4d4;
          text-align: center;
          font-size: 15px;
          color: #979797;
        }
        &-number{
          position: relative;
          //margin-left:28px;
          //margin-bottom: (10rem / 100);
          display: flex;
          align-items: center;
          
          .live-ul-li-banner-numberAfter-icon{
            width: (22.86rem / 100);
          height: (22.86rem / 100);
          margin-left:(28rem / 100);
          
          }
          
        }
        .live-detail-top-des-ul-li-number-label{
          color: #030303;
          font-weight: 500;
          margin-left:(10rem / 100);
          height: (22rem / 100);
          
        }
        .tableTitle {
          position: relative;
          //margin: 0 auto;
          width: 370px;
          height: 1px;
          background-color: #d4d4d4;
          text-align: center;
          font-size: 15px;
          color: rgba(101, 101, 101, 1);
        }
        .title{
          display: flex;
          align-items: center;
          //height:(25rem / 100);
          font-family: PingFangSC-Medium, PingFang SC;
    
          .title-icon{
            width: 9px;
            height: 16px;
            //margin-right: (12rem / 100);
            //font-size: (22rem / 100);
          }
          .title-label{
            height: (25rem / 100);
            font-weight: 500;
            font-size: (18rem / 100);
            margin-left: 7px;
            font-family: PingFangSC-Medium, PingFang SC;
          }
        }
        .title1{
          text-indent:16px;
        }
        .title2{
          text-indent:16px;
        }
      }
    }
    &-item{
      position: absolute;
      bottom: (63rem / 100);
      left: (714rem/100);
    &-mid{
      
    }
    }
  }
  .live-detail-top-bottom{
    display: flex;
    align-items: center;
    height: (50rem / 100);
    position:absolute;
    bottom:40px;
    //left:500px;
  .live-detail-top-button {
    //display: inline-block;
    position: relative;
    margin-left:10px;
    width: (149rem / 100);
    height: (50rem / 100);
    background: #761f1e;
    border-radius: (25rem / 100);
    text-align: center;
    line-height: (50rem / 100);
    font-size: (16rem / 100);
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    color: #ffffff;
    float:right;
    margin-left:15px;

  }
  .live-detail-top-bottom-number{
    position: relative;
    
    margin-left: 0px;
  }

  .bottom-tableTitle{
    position: relative;
    margin-left:15px;
    
          width: 1px;
          height: 40px;
          background-color: #d4d4d4;
          text-align: center;
          font-size: 15px;
          color: #979797;
  }
}
  .background-gray {
    background: #cbcacd;
  }
}

.detail-index-bottom-content {
  padding: 0 (16rem / 100) (146rem / 100) 0;

  &-top {
    width: (1200rem / 100);
    height: (100rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_bottom_top_bg.png")
      no-repeat;
    background-size: 100% 100%;
    box-shadow: 0 5px 15px 0 rgba(207, 181, 140, 0.8);
    display: flex;
    align-items: center;
    padding-left: (30rem / 100);
    margin-bottom: (28rem / 100);

    &-title {
      width: (140rem / 100);
      height: (31rem / 100);
      text-align: center;
      line-height: (31rem / 100);
      font-size: (20rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      background: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_title_bg.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .detail-index-bottom-content-bottom-box {
    margin-bottom: (44rem / 100);
    padding: 0 (30rem / 100);

    &-top {
      display: flex;
      align-items: center;
      font-size: (16rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      line-height: (16rem / 100);
      margin-bottom: (15rem / 100);

      &-icon {
        width: (9rem / 100);
        height: (16rem / 100);
        margin-right: (7rem / 100);
      }
    }

    &-bottom {
      padding-left: (16rem / 100);
      font-size: (14rem / 100);
      font-family: PingFangSC-Regular, PingFang SC;
      color: #273143;
      line-height: (28rem / 100);
    }
  }
}
